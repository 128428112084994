export enum PermissionConsts {
    // Pages
    Pages_Users = 'Pages.Users',
    Pages_Roles = 'Pages.Roles',
    Pages_Users_Activation = 'Pages.Users.Activation',
    Pages_Tennants = 'Pages.Tennants',
    Pages_Course = 'Pages.Course',

    // User
    User_RequestEmailConfirmation = 'User.RequestEmailConfirmation',
    User_Create = 'User.Create',
    User_Edit = 'User.Edit',
    User_Delete = 'User.Delete',
    User_ForceResetPassword = 'User.ForceResetPassword',
    User_Deactive = 'User.Deactive',
    User_UnlockLogin = 'User.UnlockLogin',
    User_Dashboard = 'User.Dashboard',
    Company_Dashboard = 'Company.Dashboard',

    // File
    File_Create = 'File.Create',
    File_Edit = 'File.Edit',
    File_Delete = 'File.Delete',
    File_GetAll = 'File.GetAll',
    File_Get = 'File.Get',

    // Course
    Course_Create = 'Course.Create',
    Course_Edit = 'Course.Edit',
    Course_Delete = 'Course.Delete',
    Course_Activate = 'Course.Activate',
    Course_AssociateTeacher = 'Course.AssociateTeacher',

    // Theme
    Theme_Create = 'Theme.Create',
    Theme_Edit = 'Theme.Edit',
    Theme_Delete = 'Theme.Delete',
    Theme_Get = 'Theme.Get',

    // Module
    Module_Create = 'Module.Create',
    Module_Edit = 'Module.Edit',
    Module_Delete = 'Module.Delete',
    Module_Get = 'Module.Get',
    Module_GetAll = 'Module.GetAll',

    // Teacher
    Teacher_Create = 'Teacher.Create',
    Teacher_Edit = 'Teacher.Edit',
    Teacher_Delete = 'Teacher.Delete',

    // Export
    UserCoursePurchase_Export = 'UserCoursePurchase.Export',
    UserModulePurchase_Export = 'UserModulePurchase.Export',

    // Lesson
    Lesson_Create = 'Lesson.Create',
    Lesson_Edit = 'Lesson.Edit',
    Lesson_Delete = 'Lesson.Delete',
    Lesson_Get = 'Lesson.Get',
    Lesson_GetAll = 'Lesson.GetAll',

    // Slider
    Slider_Create = 'Slider.Create',
    Slider_Edit = 'Slider.Edit',
    Slider_Delete = 'Slider.Delete',

    // Credit pack
    CreditPack_Skip = 'CreditPack.Skip',

    // Wizard
    Wizard_Create = 'Wizard.Create',

    //Discount Code
    DiscountCode_Create = 'DiscountCode.Create',
    DiscountCode_Edit = 'DiscountCode.Edit',
    DiscountCode_Delete = 'DiscountCode.Delete',
    DiscountCode_Get = 'DiscountCode.Get',
    DiscountCode_GetAll = 'DiscountCode.GetAll',

    // User Lesson
    UserLesson_Create = 'UserLesson.Create',
    UserLesson_Edit = 'UserLesson.Edit',
    UserLesson_Delete = 'UserLesson.Delete',
    UserLesson_Get = 'UserLesson.Get',
    UserLesson_GetAll = 'UserLesson.GetAll',
}
