import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionConsts } from '@shared/permission-consts';
import { authRouteGuard } from '@shared/auth/auth-route-guard';

const routes: Routes = [
    { path: '', redirectTo: '/redirect', pathMatch: 'full' },
    {
        path: 'redirect',
        loadComponent: () => import('shared/auth/redirect/redirect.component').then((c) => c.RedirectComponent),
    },
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then((m) => m.AccountModule), // Lazy load account module
        data: { preload: true },
    },
    {
        path: 'app',
        loadChildren: () => import('app/app.module').then((m) => m.AppModule), // Lazy load app module
        data: { preload: true },
    },
    {
        path: 'billing',
        loadChildren: () => import('./billing/billing.routes').then((m) => m.BILLING_ROUTES),
        data: { preload: true },
    },
    {
        path: 'buy-credits',
        canActivate: [authRouteGuard],
        loadComponent: () => import('./credit-pack-home/credit-pack-home.component').then((m) => m.CreditPackHomeComponent), // Lazy load app module
    },
    {
        path: 'onboarding',
        canActivate: [authRouteGuard],
        loadComponent: () => import('./wizard-first-login/wizard-first-login.component').then((m) => m.WizardFirstLoginComponent), // Lazy load app module
        data: { preload: true, permission: PermissionConsts.Wizard_Create },
    },
    {
        path: '**',
        loadChildren: () => import('./fallback/fallback.module').then((m) => m.FallbackModule), // Lazy load fallback route
        data: { preload: true },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            bindToComponentInputs: true, // Enables route params to be accessed via @Input decorator in components
            scrollPositionRestoration: 'enabled', // Resets scroll on route change
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {}
